import styled from 'styled-components';
import { queries } from '../../utils/mediaQueries';

export const Container = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  padding-left: 1rem;
  padding-right: 1rem;
  max-width: 370px;
  margin-left: auto;
  margin-right: auto;

  @media ${queries.md} {
    text-align: center;
  }
`;

export const Row = styled.div``;

export const Name = styled.div`
  margin-bottom: 20px;
  display: block;
  font-weight: 600;
  font-size: 20px;
  color: #2e384e;
  text-transform: capitalize;

  &.centered {
    text-align: center;
  }
`;

export const Description = styled.div`
  font-size: 16px;

  &.centered {
    text-align: center;
  }
`;

export const Image = styled.div`
  max-width: 270px;
  min-height: 230px;

  &.centered {
    max-width: 100%;
    & img {
      margin: 0 auto;
      display: block;
    }
  }

  @media ${queries.md} {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  & img {
    width: 100%;
    max-width: 270px;

    @media ${queries.md} {
      margin-left: auto;
      margin-right: auto;
      display: block;
    }

    @media ${queries.xs} {
    }
  }
`;
