import React from 'react';
import * as S from './Ingredient.styles';
import { Image as BuilderImage } from '@builder.io/react';

const Image = ({ image, name, centered }) => {
  if (image) {
    return (
      <S.Image className={centered ? 'centered' : ''}>
        <BuilderImage image={image} alt={name} />
      </S.Image>
    );
  } else {
    return '';
  }
};

const Ingredient = props => {
  const { name, description, image, centered } = props;

  return (
    <S.Container>
      <S.Row>
        <Image image={image} name={name} centered={centered} lazy />
        <S.Name className={centered ? 'centered' : ''}>{name}</S.Name>
        <S.Description
          dangerouslySetInnerHTML={{ __html: description }}
          className={centered ? 'centered' : ''}
        />
      </S.Row>
    </S.Container>
  );
};

export default Ingredient;
